<template>
  <IAmOverlay
    :loading="loadingPickTicket"
    :spinner-variant="'success'"
  >
    <b-card
      class="overflow-hidden"
      body-class="p-75"
    >
      <b-table
        :items="coachesBySeatTypeList"
        :fields="coachTableColumns"
        hover
        outlined
        small
        striped
        responsive
        head-variant="primary"
        thead-class="text-body align-middle text-nowrap text-center"
        tbody-class="text-body align-middle text-nowrap"
        class="rounded"
        show-empty
        :empty-text="'Không có toa, chỗ thỏa mãn điều kiện tìm kiếm'"
      >
        <template
          v-for="column in coachTableColumns"
          #[`head(${column})`]="data"
        >
          <span
            :key="column"
            class="text-body"
          >
            {{ $t(`train.search.result.columns.${data.column}`) }}
          </span>
        </template>

        <template #cell(seatGroupName)="{ item }">
          <div class="text-center fw-700">
            {{ item.seatGroupName }}
          </div>
        </template>
        <template #cell(seatGroupCode)="{ item }">
          <div class="text-center fw-700">
            {{ item.seatGroupCode }} - {{ item.seatTypeCode }}
          </div>
        </template>
        <template #cell(seatName)="{ item }">
          <div>
            {{ resolveNameByListSeatType(item.seatTypeCode) }}
          </div>
        </template>
        <template #cell(seatAvailable)="{ item }">
          <div class="text-center fw-700">
            {{ item.seatAvailable }}
          </div>
        </template>
        <template #cell(ticketSelect)="{ item }">
          <div class="d-flex-center flex-nowrap">
            <b-input-group
              style="width: 130px"
              size="sm"
              class="rounded"
            >
              <b-input-group-prepend>
                <b-button
                  v-ripple.300
                  variant="flat-danger"
                  class="btn-icon"
                  @click="() => {
                    if (item.seatNumPick > 0) {
                      item.seatNumPick -= 1
                    }
                  }"
                >
                  <!-- @click="decreaseTicket(item)" -->
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </b-input-group-prepend>

              <b-form-input
                v-model="item.seatNumPick"
                v-remove-non-numeric-chars
                lazy-formatter
                :formatter="val => fixMaxInput(val, item)"
                class="text-center font-medium-2 fw-700"
              />

              <b-input-group-append>
                <b-button
                  v-ripple.300
                  variant="flat-primary"
                  class="btn-icon"
                  @click="() => {
                    if (item.seatNumPick < Math.min(item.seatAvailable, 20)) {
                      item.seatNumPick = Number(item.seatNumPick) + 1;
                    }
                  }"
                >
                  <!-- @click="increaseTicket(item)" -->
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </template>
      </b-table>

      <div class="d-flex-between">
        <div
          v-if="stateTrainSelectedArray[itineraryIndex]"
          class="text-success font-italic fw-700 mr-1"
        >
          Tàu {{ stateTrainSelectedArray[itineraryIndex].trainCode }} đi ngày {{ convertISODateTime(stateTrainSelectedArray[itineraryIndex].departureTime).timeDate }} từ {{ stateTrainSelectedArray[itineraryIndex].departureName }} đến {{ stateTrainSelectedArray[itineraryIndex].arrivalName }}
        </div>
        <b-button
          variant="primary"
          class="mr-1 text-nowrap"
          :disabled="loadingPickTicket"
          @click="pickTicketHandle"
        >
          Xin vé
        </b-button>
      </div>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  BCard,
  BTable, BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import isNumber from 'lodash/isNumber'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'

import store from '@/store'
import IAmOverlay from '@/components/IAmOverlay.vue'
import { convertISODateTime } from '@/@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BTable,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BButton,

    IAmOverlay,
  },
  props: {
    itineraryIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { toastSuccess, toastError } = useToast()
    const coachTableColumns = ['seatGroupName', 'seatGroupCode', 'seatName', 'seatAvailable', 'ticketSelect']

    const {
      tabIndex,
      loadingPickTicket,
      stateTrainSelectedArray,
      stateTicketSelectedArray,

      getBookingCode,
      getMaxNumberOfTicketOW,

      handleClickSeat,
      getCoachNameByGroupCode,
      resolveNameByListSeatType,
    } = useTrainHandle()

    const coachesBySeatTypeList = ref([])

    function getListCoachOfTrain(seatGroup) {
      const result = []
      seatGroup.forEach(group => {
        group.seatTypes.forEach(seatType => {
          result.push({
            seatGroupName: group.name,
            seatNumPick: '',
            ...seatType,
          })
        })
      })
      coachesBySeatTypeList.value = result
    }

    watch(
      [() => tabIndex.value, () => stateTrainSelectedArray.value],
      ([newTabIndex, newTrain]) => {
        if (props.itineraryIndex === newTabIndex) {
          getListCoachOfTrain(newTrain[props.itineraryIndex].seatGroup)
        }
      },
      { immediate: true },
    )

    function decreaseTicket(data) {
      console.log(data)
    }
    function increaseTicket(data) {
      console.log(data)
    }

    function fixMaxInput(value, item) {
      if (isNil(value) || value === '') return ''
      const numValue = Number(value)
      if (isNumber(numValue) && numValue > item.seatAvailable && item.seatAvailable <= 20) {
        return item.seatAvailable
      }
      return isNumber(numValue) && numValue > 20 ? 20 : value
    }

    function pickTicketHandle() {
      const selectedList = coachesBySeatTypeList.value.filter(coach => coach.seatNumPick)
      if (isEmpty(selectedList)) {
        toastError({ title: 'Vui lòng điền vào số ghế !' })
        return
      }

      const numListTickets = stateTicketSelectedArray.value?.flat()?.length || 0
      const numAllTicketInput = selectedList.reduce((total, item) => total + Number(item.seatNumPick), 0)

      if ((numListTickets + numAllTicketInput) > getMaxNumberOfTicketOW.value) {
        Vue.swal({
          title: '<span class="fw-700 text-warning">THÔNG BÁO</span>',
          html: `<p>Bạn đã giữ <span class="text-info fw-700">${numListTickets}</span> vé. Không thể yêu cầu quá <span class="text-info fw-700">${getMaxNumberOfTicketOW.value}</span> vé.</p>`,
          icon: 'warning',
          confirmButtonText: 'Đóng',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        })
        return
      }

      const trainData = stateTrainSelectedArray.value[props.itineraryIndex]

      const payload = {
        bookingCode: getBookingCode.value,
        trainId: trainData.id,
        trainKeys: trainData.trainKeys,
        physicalTrainId: trainData.physicalTrainId,
        coachId: selectedList[0].id,
        seatGroups: selectedList.map(it => ({
          seatGroupCode: it.seatGroupCode,
          seatTypeCode: it.seatTypeCode,
          quantity: Number(it.seatNumPick),
        })),
      }

      store.dispatch('app-train/autoPickTicket', payload)
        .then(res => {
          if (!isEmpty(res?.listTicket)) {
            res.listTicket.forEach(ticket => {
              handleClickSeat(ticket)
            })
            toastSuccess({ title: 'Xin vé thành công !' })
            getListCoachOfTrain(stateTrainSelectedArray.value[props.itineraryIndex].seatGroup)
          }
          if (isEmpty(res.listTicket)) {
            toastError({ title: 'Xin vé không thành công, vui lòng chọn toa khác hoặc thử lại!' })
          }
        })
        .catch(err => {
          console.error(err)
        })
    }

    return {
      coachTableColumns,
      loadingPickTicket,
      stateTrainSelectedArray,
      decreaseTicket,
      increaseTicket,
      fixMaxInput,
      stateTicketSelectedArray,
      coachesBySeatTypeList,
      getCoachNameByGroupCode,
      resolveNameByListSeatType,
      pickTicketHandle,
      convertISODateTime,
    }
  },
}
</script>

<style lang="scss">
</style>
